body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background: #0B0C10;
  color: #C5C6C7;
  font-family: 'TextaRegular';
}

hr {
  border: none !important;
  width: 25%;
  opacity: 1 !important;
  height: 3px;
  background: linear-gradient(90deg, rgba(102,252,241,1) 0%, rgba(80,200,120,1) 100%);
}

.blue-text {
  color: #66FCF1;
}

.gradient-text {
  background-color: rgb(102,252,241);
  background-image: linear-gradient(90deg, rgba(102,252,241,1) 0%, rgba(80,200,120,1) 100%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.hr {
  background: linear-gradient(90deg, 
  rgba(102,252,241,1) 0%, 
  rgba(80,200,120,1) 100%)
  left
  bottom
  no-repeat;
  background-size: 100% 7px;
}

.hr2 {
  background: linear-gradient(90deg, 
  rgba(102,252,241,1) 0%, 
  rgba(80,200,120,1) 100%)
  left
  bottom
  no-repeat;
  background-size: 100% 5px;
}

@font-face {
  font-family: 'TextaHeavy';
  src: local('TextaHeavy'), url('../fonts/Texta/TextaHeavy.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'TextaRegular';
  src: local('TextaRegular'), url('../fonts/Texta/TextaRegular.ttf') format('truetype');
  font-weight: bold;
}

.header {
  font-family: 'TextaHeavy';
}

.greeting {
  font-size: 130%;
}

.card-dimension {
  background-color: #0B0C10 !important;
}

.full-screen {
  height: 100vh;
}

.card {
  transition: all 0.3s;
  transform: scale(0.99);
}

.card:hover {
  transform: scale(1.0);
}

.form-sizing {
  width: 100%;
  max-width: 830px;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.transparent {
  background: transparent;
  transition: background-color 0.3s ease-in-out;
}

.notTransparent {
  transition: background-color 0.3s ease-in-out;
}

.noOverflow {
  overflow-x: hidden;
}

.transparent2 {
  background: transparent;
  z-index: 5;
}

.profile-picture {
  max-height: 1000px;
}