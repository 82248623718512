.draw-border {
    box-shadow: inset 0 0 0 4px #C5C6C7;
    color: #C5C6C7;
    transition: color 0.25s 0.0833333333s;
    position: relative;
}
.draw-border::before, .draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
}
.draw-border::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
}
.draw-border::after {
    border-top-width: 4px;
    border-right-width: 4px;
}
.draw-border:hover {
    color: #66FCF1;
}
.draw-border:hover::before, .draw-border:hover::after {
    border-color: #66FCF1;
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
}
.draw-border:hover::before {
    transition-delay: 0s, 0s, 0.25s;
}
.draw-border:hover::after {
    transition-delay: 0s, 0.25s, 0s;
}

.btn {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    font: 700 1.2rem 'Roboto Slab', sans-serif;
    padding: 1em 2em;
    letter-spacing: 0.05rem;
}
.btn:focus {
    outline: 2px dotted #C5C6C7;
}