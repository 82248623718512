.hidden {
    opacity: 0;
    transform: translate(0, 10vh);
    transition-property: opacity, transform;
    transition-duration: 2s;
}

.notHidden {
    opacity: 1;
    transform: translate(0, 0);
    transition-property: opacity, transform;
    transition-duration: 2s;
}

.hidden2 {
    opacity: 0;
    transform: translate(-10vw, 0);
    transition-property: opacity, transform;
    transition-duration: 2s;
}

.notHidden2 {
    opacity: 1;
    transform: translate(0, 0);
    transition-property: opacity, transform;
    transition-duration: 2s;
}

.hidden3 {
    opacity: 0;
    transform: translate(10vw, 0);
    transition-property: opacity, transform;
    transition-duration: 2s;
    visibility: hidden;
}

.notHidden3 {
    opacity: 1;
    transform: translate(0, 0);
    transition-property: opacity, transform;
    transition-duration: 2s;
    visibility: visible;
}

.delay-1 {
    transition-delay: 0.25s;
}

.delay-2 {
    transition-delay: 0.5s;
}